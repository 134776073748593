import { PageBlocksGallerySpacingDesktop } from '@generated/types';
import { CSSProperties } from 'react';
import { assignUnits } from '.';

export const getSpacingVariables = (data: {
  spacingDesktop?: Omit<PageBlocksGallerySpacingDesktop, '__typename'> | null;
  spacingMobile?: Omit<PageBlocksGallerySpacingDesktop, '__typename'> | null;
}) =>
  ({
    '--spacing-mt-desktop':
      assignUnits(
        data?.spacingDesktop?.marginTop &&
          (data?.spacingDesktop?.marginTopOverride || '200px'),
      ) || '0px',
    '--spacing-mb-desktop':
      assignUnits(
        data?.spacingDesktop?.marginBottom &&
          (data?.spacingDesktop?.marginBottomOverride || '200px'),
      ) || '0px',
    '--spacing-mt-mobile':
      assignUnits(
        data?.spacingMobile?.marginTop &&
          (data?.spacingMobile?.marginTopOverride || '200px'),
      ) || '0px',
    '--spacing-mb-mobile':
      assignUnits(
        data?.spacingMobile?.marginBottom &&
          (data?.spacingMobile?.marginBottomOverride || '200px'),
      ) || '0px',
  }) as CSSProperties;

export const getSpacingProps = (data: {
  spacingDesktop?: Omit<PageBlocksGallerySpacingDesktop, '__typename'> | null;
  spacingMobile?: Omit<PageBlocksGallerySpacingDesktop, '__typename'> | null;
}) => ({
  'data-fh': data?.spacingDesktop?.fullHeight,
  'data-fhm': data?.spacingMobile?.fullHeight,
  style: getSpacingVariables(data),
});

/* separation point between mobile and desktop (md: is a separation point - can be changed to*/
export const spacingClasses = `
data-[fhm=true]:-lg:fullscreen lg:data-[fh=true]:fullscreen
!mt-[--spacing-mt-mobile] !mb-[--spacing-mb-mobile] lg:!mt-[--spacing-mt-desktop] lg:!mb-[--spacing-mb-desktop]
`;
