'use client';
import { Site } from '@apps/site';
import { GlobalPartsFragment, SettingsPartsFragment } from '@generated/types';
import { RecursiveOmit } from '../Blocks/AnimationText';
import FullImageVideo from '../Blocks/FullImageVideo';
import { Layout } from '../Layout';

export function NotFoundPage({
  site,
  global,
  settings,
}: {
  site: Site;
  global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
  settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
}) {
  return (
    <>
      <Layout {...{ global, settings }} pageId={'not-found'} site={site}>
        <FullImageVideo
          data={{
            headline: {
              text: '404 - Page not found',
              fontWeight: 'Regular',
              fontSize: 'H1',
            },
            media: '/not_found.mp4',
            variant: 'Screen Height',
            shapeOptions: {
              left: 'Empty',
              middle: 'Empty',
              right: 'Empty',
            },
            shapePosition: 'Bottom',
            homeButton: true,
          }}
          site={site}
        />
      </Layout>
    </>
  );
}
